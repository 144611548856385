import React from 'react';

const WorkGallery = () => (
  <section className="gallery">
    <h2>Work</h2>
    <div className="gallery-grid">
      <img src="/assets/images/placeholder1.jpg" alt="Gallery item 1" />
      <img src="/assets/images/placeholder2.png" alt="Gallery item 2" />
      <img src="/assets/images/placeholder3.jpg" alt="Gallery item 3" />
    </div>
  </section>
);

export default WorkGallery;
