import React, { useState } from 'react';
import AboutSidebar from './components/AboutSidebar';
import WorkGallery from './components/WorkGallery';
import ServicesPanel from './components/ServicesPanel';
import JobTiles from './components/JobTiles';

const App = () => {
  const [activeTab, setActiveTab] = useState('Work');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Work':
        return <WorkGallery />;
      case 'Services':
        return <ServicesPanel />;
      case 'Jobs':
        return <JobTiles />;
      default:
        return <WorkGallery />;
    }
  };

  return (
    <div className="app-container">
      <div className="main-layout">
        {/* Sidebar */}
        <AboutSidebar />

        {/* Content Wrapper */}
        <div className="content-area">
          {/* Title Panel */}
          <header className="title-panel">
            <h1>Sam Phelps Drone Photography Portfolio</h1>
          </header>

          {/* Tabs Navigation */}
          <div className="nav-tabs">
            <a
              href="#"
              onClick={() => setActiveTab('Work')}
              className={activeTab === 'Work' ? 'active' : ''}
            >
              Work
            </a>
            <a
              href="#"
              onClick={() => setActiveTab('Services')}
              className={activeTab === 'Services' ? 'active' : ''}
            >
              Services
            </a>
            <a
              href="#"
              onClick={() => setActiveTab('Jobs')}
              className={activeTab === 'Jobs' ? 'active' : ''}
            >
              Jobs
            </a>
          </div>

          {/* Tab Content */}
          <div className="tab-content">{renderTabContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default App;
