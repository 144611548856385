import React from 'react';

const JobTiles = () => (
  <section className="jobs">
    <h2>Jobs</h2>
    <div className="job-tile-grid">
      <img src="/assets/images/placeholder1.jpg" alt="Job preview 1" />
      <img src="/assets/images/placeholder2.png" alt="Job preview 2" />
      <img src="/assets/images/placeholder3.jpg" alt="Job preview 3" />
    </div>
  </section>
);

export default JobTiles;
