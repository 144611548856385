import React from 'react';

const AboutSidebar = () => (
  <div className="sidebar">
    <img
      src="/assets/images/profile.jpg"
      alt="Profile"
      className="profile-image"
    />
    <h2>About Me</h2>
    <p>
      Licensed drone operator and multimedia artist specializing in aerial
      photography and video production.
    </p>
    <p>
      <strong>Contact:</strong>
      <br />
      Email: your.email@example.com
      <br />
      Phone: +123456789
      <br />
      Location: Dayton, OH
    </p>
    <div className="social-links">
      <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
        📷
      </a>
      <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer">
        🎥
      </a>
      <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
        📘
      </a>
      <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
        🔗
      </a>
      <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
        ▶
      </a>
    </div>
  </div>
);

export default AboutSidebar;
