import React from 'react';

const ServicesPanel = () => (
  <section className="services">
    <h2>Services</h2>
    <div className="service-panel">
      <h3>Aerial Photography</h3>
      <p>Capture stunning aerial views for real estate, events, and more.</p>
      <span>$100/hour</span>
    </div>
    <div className="service-panel">
      <h3>Event Photography</h3>
      <p>Professional photography for events.</p>
      <span>$150/hour</span>
    </div>
    <div className="service-panel">
      <h3>Video Production</h3>
      <p>Professional video editing and production.</p>
      <span>$150/hour</span>
    </div>
  </section>
);

export default ServicesPanel;
